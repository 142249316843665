import React from 'react';
import './styles.scss';

const ServicesAssistance = ({ info }) => {
    const renderDescriptionList = (text) => {
        if (!text) return null;
        const items = text.split('-').map(item => item.trim()).filter(Boolean);
        return (
            <ul className="main-list">
                {items.map((item, index) => {
                    const subItems = item.split('°').map(sub => sub.trim()).filter(Boolean);
                    return (
                        <li key={index} className="main-item">
                            {subItems[0]}
                            {subItems.length > 1 && (
                                <ul className="sub-list">
                                    {subItems.slice(1).map((sub, subIndex) => (
                                        <li key={subIndex} className="sub-item">{sub}</li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderContent = (items = []) => {
        return items.map((item, index) => (
            <div key={index} className="item-card">
                {item.title?.length > 0 && (
                    <h3 className="item-title">
                        {item.title.map((t, i) => (
                            <span key={i}>{t.text}</span>
                        ))}
                    </h3>
                )}
                {item.description1?.length > 0 && (
                    <p className="list__protects-item--parag item-subtitle description-title">
                        {item.description1.map(d => d.text).join(' ')}
                    </p>
                )}
                {item.subtitle?.length > 0 && (
                    <h4 className="item-subtitle">
                        {item.subtitle.map((s, i) => (
                            <span key={i} style={{ fontWeight: 'bold' }}>{s.text}</span>
                        ))}
                    </h4>
                )}
                {item.description21?.length > 0 && (
                    <ul className="list__protects-item--parag item-description">
                        {renderDescriptionList(item.description21.map(d => d.text).join(' '))}
                    </ul>
                )}
            </div>
        ));
    };

    const groupItemsByImage = (items = []) => {
        let groupedItems = [];
        let currentGroup = [];
        let currentImage = '';

        items.forEach((item) => {
            const imageUrl = item.image1?.url || '';

            if (imageUrl !== '' && imageUrl !== currentImage) {
                if (currentGroup.length > 0) {
                    groupedItems.push({ imageUrl: currentImage, items: currentGroup });
                }
                currentImage = imageUrl;
                currentGroup = [item];
            } else {
                currentGroup.push(item);
            }
        });

        if (currentGroup.length > 0) {
            groupedItems.push({ imageUrl: currentImage, items: currentGroup });
        }

        return groupedItems;
    };

    const groupedItems = groupItemsByImage(info?.items || []);

    const groupItemsByTitle = (items) => {
        let groupedItems = [];
        let currentGroup = [];
        let currentTitle = '';

        items.forEach((item) => {
            const title = item.title && item.title.length > 0 ? item.title[0].text : '';
            if (title !== '' && title !== currentTitle) {
                if (currentGroup.length > 0) {
                    groupedItems.push({
                        title: currentTitle,
                        items: currentGroup
                    });
                }
                currentTitle = title;
                currentGroup = [item];
            } else {
                currentGroup.push(item);
            }
        });

        if (currentGroup.length > 0) {
            groupedItems.push({
                title: currentTitle,
                items: currentGroup
            });
        }

        return groupedItems;
    };

    return (
        <div className="group-life-container">
            <h2 className="title__ppal title__ppal--center title__ppal--sm title__ppal--dr title__ppal--line title__ppal--line-c-3 title__ppal--extra-bold title">
                {info?.primary?.title?.length > 0 ? (
                    info.primary.title.map((t, i) => (
                        <strong key={i}><span>{t.text}</span></strong>
                    ))
                ) : (
                    <strong><span>Título no disponible</span></strong>
                )}
            </h2>
            <h4 className="title__ppal title__ppal--center title__ppal--sm title__ppal--dr title__ppal--extra-bold title subtitle item-title">
                {info?.primary?.subtitulo?.length > 0 ? (
                    info.primary.subtitulo.map((t, i) => (
                        <strong key={i}><span>{t.text}</span></strong>
                    ))
                ) : (
                    <strong><span>Subtítulo no disponible</span></strong>
                )}
            </h4>
            <div className="container-group-life">
                {groupedItems.length > 0 ? (
                    groupedItems.map((group, index) => (
                        <div key={index} className="page-box">
                            <div className="container-backgrounds">
                                {group.imageUrl && (
                                    <img src={group.imageUrl} alt="Background" className="background-image" />
                                )}
                            </div>
                            {renderContent(group.items)}
                        </div>
                    ))
                ) : (
                    <p className="no-data-message">No hay información disponible.</p>
                )}
            </div>
        </div>
    );
};

export default ServicesAssistance;
