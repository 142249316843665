import React, { useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Select } from 'antd'
import { RichText } from 'prismic-reactjs'
import { PortalAgentesContext, PortalAgentesFormContext } from 'context/context';
import { Microservice } from 'services/microservice';

// @utils
import { replaceAccents } from 'utils/replace-accents'
import { useMsal } from '@azure/msal-react';

const capitalize = (s) => {
	if (typeof s !== 'string') return ''
	s = s.toLowerCase()
	return s.charAt(0).toUpperCase() + s.slice(1)
}

const FieldSelect = ({ data }) => {
	const { pathname } = useLocation();
	const { value: { user } } = useContext(PortalAgentesContext);
	const {
		handle: { handleSelectChange },
		value: { dataFields, showAlert }
	} = useContext(PortalAgentesFormContext);
	const [options, setOptions] = useState([]);
	const { instance, accounts } = useMsal();

	const getOptions = async () => {
		const body = {
			method: 'get',
			url: `${data.url_service}`,
			body: {}
		}
		if (data.url_service !== null) {
			const res = await Microservice(body, user.idToken, "management", instance, accounts);
			if(data.key === 'solution' && pathname?.includes('/polizas/')){
				const options = res?.map((x) => ({ clave: x?.descripcion, descripcion: x?.descripcion }));
				setOptions(options);
			}else setOptions(res);
		} else {
			if (data.key === 'userId') {
				const userOptions = [];
				user.assignedUsers.forEach((x) => {
					userOptions.push({ clave: x.cdUsuario, descripcion: x.nombreUsuario });
				});
				setOptions(userOptions);
			}
			if(data.key === 'status' && data?.options?.length > 0){
				const info = data.options[0]?.text;
				const options = info?.split(',')?.map((x) => ({ clave: x, descripcion: capitalize(x) }));
				setOptions(options);
			}
		}

	};

	useEffect(() => {
		if (user.idToken.length) {
			if (data.options.length <= 1) {
				getOptions();
			}
		}
	}, [data, user.idToken]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (data?.defaultvalue && !dataFields[data.key]) {
			handleSelectChange(data.key, data?.defaultvalue);
		}
	}, [options, data?.defaultvalue, dataFields[data.key]]);

	return (
		<Col xs={24} sm={12} md={12} lg={8}>
			<div className={`ant-row ant-form-item ${dataFields[data.key] !== null ? 'ant-form-item-has-success' : ''}`}>
				<div className="ant-col ant-form-item-label">
					<label
						id={`slct-${replaceAccents(RichText.asText(data.name))}-label`}
					>
						{RichText.asText(data.name)}
					</label>
				</div>
				<Select
					id={`slct-${replaceAccents(RichText.asText(data.name))}`}
					onChange={(e) => handleSelectChange(data.key, e)}
					getPopupContainer={trigger => trigger.parentNode}
					value={dataFields[data.key]}
					disabled={data?.disabled ?? false}
				>
					{options && options.length ?
						options.map((option, k) => (
							<Select.Option value={option.clave} key={k}>
								{option.descripcion}
							</Select.Option>
						)) : null
					}
				</Select>
				{data.key === 'solucion' && showAlert ?
					(
						<div className="alert alert-message">
							Debe seleccionar una opción.
						</div>
					) : null
				}
			</div>
		</Col >
	)
}

export default FieldSelect